<template>
    <div id="ApprovalFlowPlanningGroup">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                :checkboxFilters="checkboxFilters"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"
                        @setCheckboxValue="setCheckboxValue($event)"

                        @setFilterValue="setFilterValue"
                        @clearFilteredBy="clearFilteredBy"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'ApprovalFlowPlanningGroup',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [
                this.$ipApprovalFlow,
                'approval-flow-planning-group',
                {
                    conditions: [
                        // {
                        //     AndOr: 'AND',
                        //     column: 'id_module',
                        //     operator: '=',
                        //     value: 5
                        // }
                    ]
                }
            ]
        },
    },

    data: () => ({
        tableName: 'Dinâmica grupos de planejamento',
        tableIcon: 'mdi-clipboard-flow',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        options: [
            {
                column: 'id_approval_flow',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Fluxo de aprovação',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipApprovalFlow, 'approval-flow' ],
                filters: ['id_planning_group_version', 'id_approval_flow_level'],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 6
                    }
                ]
            },
            {
                column: 'id_planning_group_version',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Versão do grupo de planejamento',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group-version' ],
                filters: ['id_planning_group'],
                filteredBy: ['id_approval_flow'],
                filteredItems: [],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 6
                    }
                ]
            },
            {
                column: 'id_planning_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo de planejamento',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ],
                filteredBy: ['id_planning_group_version'],
                filteredItems: [],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 6
                    }
                ]
            },
            {
                column: 'id_approval_flow_level',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Nível do fluxo de aprovação',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipApprovalFlow, 'approval-flow-level' ],
                filteredBy: ['id_approval_flow'],
                filteredItems: [],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 6
                    }
                ]
            },
            {
                column: 'id_event_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo do evento',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event-type' ]
            },
            {
                column: 'id_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSecurity, 'group' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'tag',
                        operator: 'NOT LIKE',
                        value: "%_REPORT%"
                    }
                ]
            },
            {
                column: 'showUsers',
                is: 'Checkbox',
                value: false,
                label: 'Mostrar usuários',
                required: false,
            },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        checkboxFilters: [],
        loadTable: false,
        clearFilters: false,
    }),

    created() {
        let checkboxOptions = this.options.filter(o => o.is === 'Checkbox')
        checkboxOptions.forEach(c => {
            this.checkboxFilters.push({ column: c.column, value: c.value })
        })
    },

    methods: {
        setCheckboxValue(e) {
            let checkbox = this.checkboxFilters.find(c => c.column === e.column)
            checkbox.value = e.value
        },
        
        async setFilterValue(e) {
            e.filters.forEach(async f => {
                let option = this.options.find(o => o.column === f)
                if (option) {
                    if (e.value.selected) {
                        let filter = {
                            conditions: [
                                {
                                    AndOr: 'AND',
                                    column: e.value.column,
                                    operator: '=',
                                    value: e.value.selected.id
                                }
                            ]
                        }
    
                        try {
                            const res = await this.$http.post(option.endpoint[0] + option.endpoint[1] + '/list-options', { filter })
                            if (res) {
                                option.filteredItems = res.data.rows
                            }
                        } catch (err) {
                            this.$fnError(err)
                        }
                    } else {
                        option.filteredItems = []
                    }

                }
            })
        },

        clearFilteredBy() {
            this.options.forEach(async o => {
                if (o.filteredBy) {
                    o.filteredItems = []
                }
            })
        },

        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>